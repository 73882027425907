const CORS_AND_CACHE_PROXY_URL_PREFIX = "/api/gis-source-proxy-url";
const CORS_AND_CACHE_PROXY_DOMAIN_URL_PREFIX = `https://app.vind.ai${CORS_AND_CACHE_PROXY_URL_PREFIX}`;

export const addCorsAndCacheProxyURL = (url: string) => {
  if (url.startsWith(CORS_AND_CACHE_PROXY_DOMAIN_URL_PREFIX)) return url;

  const urlWithEncodedSearchParams = keepParams(url);
  return `${CORS_AND_CACHE_PROXY_URL_PREFIX}/${urlWithEncodedSearchParams}`;
};

// Encode any existing query params and put the encoded string in the "keepParams" parameter
const keepParams = (url: string): string => {
  const urlObj = new URL(url);
  if (!urlObj.search) {
    return url.toString();
  }

  const items = urlObj.search
    .replace("?", "")
    .split("&")
    .map((searchItem) => searchItem.split("="));

  const searchString = items
    .filter(([key, val]) => key && val)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  urlObj.search = "";
  urlObj.searchParams.append("keepParams", searchString);
  return urlObj.toString();
};
